<template>
  <div class="login-wrap">
    <header>
        <div @click="$router.push('/index')">首页</div>
    </header>
    <div class="login">
        <div class="box">
        <div>
            <el-form ref="form" :rules="rules" :model="form">
                <el-form-item label="" prop="username">
                    <el-input v-focus v-model="form.username" placeholder="请输入手机号" @keyup.enter.native="submitForm"></el-input>
                </el-form-item>
                <el-form-item label="" prop="password">
                    <el-input show-password v-model="form.password" placeholder="请输入密码" @keyup.enter.native="submitForm"></el-input>
                </el-form-item>
            </el-form>
            <!-- <p>忘记密码？</p> -->
            <el-button @click="submitForm()">登 录</el-button>
        </div>
        </div>
    </div>
    <div class="footer">
    <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '../../components/footer.vue';
import { toLogin } from '../../api/login'
  export default {
      name: 'Login',
      data () {
          return {
            showPass: false,
            form: {
                username: '',
                password: ''
            },
            rules: {
                username: [
                    { required: true, message: '账号不能为空', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur' },
                    // { min: 4, max: 12, message: '密码长度为4-12位', trigger: 'blur' }
                ]
            }
          }
      },
      directives: {
        focus: {
            inserted: function(el) {
                el.querySelector('input').focus();
            }
        }
      },
      components: {
        Footer,
      },
      methods: {
          login() {
            // const loading = this.$loading({
            //     lock: true,
            //     text: 'Loading',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0,0,0,.7)'
            // });
            // setTimeout(() => {
            //     loading.close();
            // },5000)
            toLogin(this.form).then(res => {
                // loading.close();
                const { code, message} = res
                if(code == 200) {
                    this.$router.push('/menu');
                    this.$message.success(message);
                    sessionStorage.setItem('token', res.result.token);
                    sessionStorage.setItem('roleInfo',JSON.stringify(res.result.roleInfo));
                    sessionStorage.setItem('userInfo',JSON.stringify(res.result.userInfo));
                    
                }else {
                    this.$message.error(message);
                }
            })
          },
          submitForm() {
              this.$refs.form.validate((valid) => {
                  if (valid) {
                      this.login()
                  }else{
                      console.log("error login!!");
                  }
              })
          }
      }
  }
</script>

<style scoped lang="less">
.login-wrap{
    display: flex;
    height: 100vh;
    flex-direction: column;
}
header{
    background-color: #242424;
    color: #fff;
    min-width: 1200px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    div{
        cursor: pointer;
        background: #de474e;
        width: 98px;
        text-align: center;
    }
}
.login {
    width: 100%;
    height: 100%;
    flex: 1;
    /*背景图片*/
    background-image: url('../../assets/image/login-bg.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.box{
    padding: 20px 0;
    font-size: 14px;
    background: rgba(0,0,0, .4);
    div{
        width: 300px;
        margin: 0 auto;
        p{
            color: #fff;
            float: right;
            margin: 0 20px 10px 0;
            cursor: pointer;
        }
    }
    .el-form-item{
        margin-bottom: 22px;
    }
    /deep/ .el-input__inner{
        color: #fff;
        border-radius: 30px ;
        background: rgba(0,0,0, 0);
    }
    .el-button{
        border-radius: 30px ;
        display: block;
        width: 100%;
        color: #fff;
        background: red;
        border: none;
    }
}
/deep/ .el-input__inner::placeholder{
    color: #ccc;
}
/deep/ .el-input__inner::-webkit-input-placeholder{
color: #ccc;
}
/deep/ .el-input__inner::-moz-input-placeholder{
    color: #ccc;
}
/deep/ .el-input__inner::-ms-input-placeholder{
    color: #ccc;
}
</style>